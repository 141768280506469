import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import transformPost from "../transformers/transformPost";
import { Post, PostExt, PostId } from "../types";

export const getPostQueryKey = (
  postId: PostId,
  accessToken?: string
): string[] => ["post-thread", String(postId), accessToken].filter(Boolean);

export interface GetPostResponse {
  nested_replies: Post[];
  replies: Post[];
  post: Post;
}

export interface PostResult {
  nested_replies: Post[];
  replies: PostExt[];
  post: PostExt;
}

export const getPost = (
  postId: PostId,
  accessToken?: string,
  signal?: AbortSignal
): Promise<PostResult> =>
  api
    .get<GetPostResponse>(
      `/v1/posts/${postId}?minimize_response_size=1`,
      accessToken
        ? {
            headers: { Authorization: accessToken },
            signal,
          }
        : { signal }
    )
    .then(({ data }) => ({
      ...data,
      post: transformPost(data.post),
      replies:
        Array.isArray(data.replies) &&
        data.replies.map((r) => transformPost(r)),
    }))
    .then((post) => ({
      ...post,
      replies: post?.replies
        .filter(({ is_visible }) => is_visible)
        .slice(0, accessToken ? post.replies.length : 6),
    }));

export const usePost = (postId: PostId): UseQueryResult<PostResult, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery({
    queryKey: getPostQueryKey(postId, accessToken),
    queryFn: ({ signal }) => getPost(postId, accessToken, signal),
  });
};
