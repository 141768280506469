import htmr from "htmr";
import Linkify from "linkify-react";
import linkifyString from "linkify-string";
import TruncateMarkup from "react-truncate-markup";

import { usePostContentContext } from "./context";

interface Props {
  body: string;
  hasTitle?: boolean;
}

const LINKIFY_OPTIONS = {
  className: "underline break-all",
  target: {
    url: "_blank",
  },
  // Because this is user-generated content, we want to tell search engines not
  // to crawl this link.
  rel: "nofollow",
};

const Body = ({ body, hasTitle = false }: Props): JSX.Element => {
  const { variant } = usePostContentContext();

  return (
    <div className="whitespace-pre-wrap break-words">
      {["single", "reply"].includes(variant) && (
        <Linkify
          options={LINKIFY_OPTIONS}
          tagName={!hasTitle && variant === "single" ? "h1" : "p"}
        >
          {body}
        </Linkify>
      )}

      {["feed", "discover"].includes(variant) && (
        <TruncateMarkup
          lines={5}
          ellipsis={
            <span className="">
              ... <br />
              <span className="text-teal-600 font-bold text-[15px]">
                Continue reading
              </span>
            </span>
          }
        >
          <p className="text-14px tablet:text-16px">
            {htmr(linkifyString(body, LINKIFY_OPTIONS))}
          </p>
        </TruncateMarkup>
      )}
    </div>
  );
};

export default Body;
