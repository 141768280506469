import cn from "classnames";
import Link from "next/link";
import { FC } from "react";

import { usePostContentContext } from "./context";
import { Variant } from "./types";

interface Props {
  title: string;
}

const Wrapper: FC = ({ children }) => {
  const { variant, postId } = usePostContentContext();

  if (["feed", "discover"].includes(variant)) {
    return (
      <Link href={`/community/posts/${postId}`} passHref>
        {children}
      </Link>
    );
  }

  return <>{children}</>;
};

const getTagName = (variant: Variant) => {
  switch (variant) {
    case "single":
      return "h1";
    case "feed":
      return "a";
    case "discover":
      return "a";
    default:
      return "p";
  }
};

const PostTitle: FC<Props> = ({ title }) => {
  const { variant } = usePostContentContext();
  const Component = getTagName(variant);

  return (
    <Wrapper>
      <Component className={cn("block text-18px font-bold")}>{title}</Component>
    </Wrapper>
  );
};

export default PostTitle;
