import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import { PostAction, PostId } from "../types";

export const getQueryKey = (
  postId: PostId,
  accessToken: string
): [string, string, string] => ["post-actions", String(postId), accessToken];

export const usePostActions = (
  postId: PostId,
  options: UseQueryOptions<undefined, AxiosError, PostAction[]> = {}
): UseQueryResult<PostAction[], AxiosError> => {
  const { data: session } = useSession();
  const { accessToken } = session;

  return useQuery({
    queryKey: getQueryKey(postId, accessToken),
    queryFn: ({ signal }) =>
      api
        .get<{ context_menu_items: PostAction[] }>(
          `/v1/community/post_context_menu?post_id=${postId}`,
          {
            headers: { Authorization: accessToken },
            signal,
          }
        )
        .then(({ data }) => data.context_menu_items),
    ...options,
  });
};
