import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import ErrorScreen from "@core/layout/ErrorScreen";
import IntakeCallSchedulerModal from "@core/scheduler-modals/IntakeCallSchedulerModal";
import Modal from "@core/ui/Modal";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import PencilIcon from "@heroicons/react/outline/PencilIcon";
import cn from "classnames";
import { useEffect, useState } from "react";
import { create } from "zustand";

import Feed from "../components/Feed";
import LoadingFeed from "../components/Feed/LoadingFeed";
import LoginPrompt from "../components/LoginPrompt";
import NewPost from "../components/NewPost";
import PageWrapper from "../components/PageWrapper";
import useIsConquerorPost from "../hooks/useIsConquerorPost";

export const newPostDialogStore = create<{
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}>((set) => ({
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  onOpen: () => set({ isOpen: true }),
}));

interface MainFeedScreenProps {
  segmentId?: string;
}

export function MainFeedScreen({
  segmentId,
}: MainFeedScreenProps): JSX.Element {
  const [error, setError] = useState<Error | null>(null);

  // User/session
  const { data: session, isLoading: isLoadingSession } = useSession();
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { accessToken } = session ?? {};
  const isAuthenticated = !!accessToken;

  // Dialog/modal state
  const [hasNewPostModalOpened, setHasNewPostModalOpened] = useState(false);
  const { isConquerorPostEnabled } = useIsConquerorPost();
  const {
    isOpen: isNewPostDialogOpen,
    onClose: closeNewPostDialog,
    onOpen: openNewPostDialog,
  } = newPostDialogStore();

  useEffect(() => {
    if (isConquerorPostEnabled && !hasNewPostModalOpened) {
      openNewPostDialog();
      setHasNewPostModalOpened(true);
    }
  }, [hasNewPostModalOpened, isConquerorPostEnabled, openNewPostDialog]);

  const [schedulerState, setSchedulerState] = useState({
    isOpen: false,
    source: "web_app_community_feed",
  });

  const { openModal } = useAuthenticationModal();

  const enableParentalControl = !!user?.childAdolescentViewEnabled;

  return (
    <PageWrapper
      classes={{ container: "!pb-0" }}
      hideDiscover={enableParentalControl}
      hideSearch={enableParentalControl}
      hideFilters={enableParentalControl}
      hideFavorites={enableParentalControl}
      hideSidebar={enableParentalControl}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        isLoadingSession || isLoadingUser ? (
          <LoadingFeed />
        ) : error ? (
          <ErrorScreen error={error} />
        ) : (
          <div className="relative">
            <div className="space-y-3">
              {!enableParentalControl ? (
                <NewPost className="tablet:flex hidden shadow-sm border border-gray-200" />
              ) : null}

              <Feed
                setError={setError}
                segmentId={segmentId}
                isAuthenticated={isAuthenticated}
              />

              {!enableParentalControl ? (
                <button
                  type="button"
                  onClick={
                    isAuthenticated
                      ? () => openNewPostDialog()
                      : () =>
                          openModal({
                            redirectAfterAuthentication: false,
                            variant: "community",
                          })
                  }
                  className="tablet:hidden fixed bottom-4 right-4 rounded-full bg-teal-600 p-4 text-white z-[2]"
                >
                  <PencilIcon className="h-8 w-8" />
                </button>
              ) : null}

              {isAuthenticated ? (
                <Modal
                  onClose={() => closeNewPostDialog()}
                  isOpen={isNewPostDialogOpen}
                >
                  <NewPost
                    onSuccess={() => closeNewPostDialog()}
                    className="tablet:shadow-none"
                  />

                  <Modal.CloseButton onClick={() => closeNewPostDialog()} />
                </Modal>
              ) : null}

              <IntakeCallSchedulerModal
                source={schedulerState.source}
                guid={user?.guid}
                email={user?.email}
                isOpen={schedulerState.isOpen}
                handleClose={() =>
                  setSchedulerState((previousState) => ({
                    ...previousState,
                    isOpen: false,
                  }))
                }
              />
            </div>

            {!isAuthenticated && (
              <LoginPrompt
                className={cn(
                  "bg-gradient-to-b from-transparent via-white to-white z-1",
                  "absolute -bottom-32 pb-32 pt-92"
                )}
              />
            )}
          </div>
        )
      }
    </PageWrapper>
  );
}
