import useModalWithDelayedCallback from "@core/hooks/useModalWithDelayedCallback";
import usePrompt from "@core/hooks/usePrompt";
import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import ContainedPageWithHeading from "@core/layout/ContainedPageWithHeading";
import ErrorScreen from "@core/layout/ErrorScreen";
import Avatar from "@core/ui/Avatar";
import Input from "@core/ui/Input";
import Loader from "@core/ui/Loader";
import Modal from "@core/ui/Modal";
import Textarea from "@core/ui/Textarea";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouterScroll } from "@moxy/next-router-scroll";
import cn from "classnames";
import { isEmpty, throttle, trim, truncate } from "lodash";
import { NextSeo } from "next-seo";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Scroll from "react-scroll";
import * as yup from "yup";

import ExitPromptModal from "../components/ExitPromptModal";
import OcdJourneySidebar from "../components/JourneySidebar";
import LoginPrompt from "../components/LoginPrompt";
import PostCard from "../components/PostCard";
import UnsavedChangesWatcherCommunity from "../components/UnsavedChangesWatcherCommunity";
import { useMutatePostReply } from "../hooks/useMutatePostReply";
import { usePost } from "../hooks/usePost";
import { PostAvatarSizes, PostId, PostTypes } from "../types";

const { scroller } = Scroll;

const FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS =
  "https://assets.treatmyocd.com/images/avatars/avatar_0_circle.png";

const getAvatarSizeByDepth = (depth: number) => {
  switch (depth) {
    case 0:
      return PostAvatarSizes.LARGE;
    case 1:
      return PostAvatarSizes.MEDIUM;
    default:
      return PostAvatarSizes.SMALL;
  }
};

const schema = yup.object({
  replyBody: yup.string().required(),
});

interface FormValues {
  replyBody: string;
}

interface PostThreadScreenProps {
  postId: PostId;
}

export function PostThreadScreen({
  postId,
}: PostThreadScreenProps): JSX.Element {
  const { openModal } = useAuthenticationModal();

  const {
    data: post,
    isLoading: isLoadingPosts,
    error: postError,
  } = usePost(postId);
  const { mutateAsync: replyToPost } = useMutatePostReply();
  const [postIdToReplyTo, setPostIdToReplyTo] = useState<number>(null);

  const {
    isOpen: isReplyDialogOpen,
    open: openReplyDialog,
    close: closeReplyDialog,
  } = useModalWithDelayedCallback();
  const {
    data: session,
    isLoading: isLoadingSession,
    error: sessionError,
  } = useSession();
  const { data: user, isLoading: isLoadingUser, error: userError } = useUser();

  const [isWindowScrolled, setIsWindowScrolled] = useState(false);

  const inlineReplyForm = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register: registerInlineReplyForm,
    formState: inlineReplyFormState,
    reset: resetInlineReplyForm,
  } = inlineReplyForm;
  const {
    isSubmitting: isSubmittingInlineReplyForm,
    isValid: isInlineReplyFormValid,
  } = inlineReplyFormState;

  const pinnedInlineReplyForm = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register: registerPinnedInlineReplyForm,
    formState: pinnedInlineReplyFormState,
    reset: resetPinnedInlineReplyForm,
  } = pinnedInlineReplyForm;
  const {
    isSubmitting: isSubmittingPinnedInlineReplyForm,
    isValid: isPinnedInlineReplyFormValid,
  } = pinnedInlineReplyFormState;

  const modalReplyForm = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register: registerModalReplyForm,
    formState: modalReplyFormState,
    reset: resetModalReplyForm,
  } = modalReplyForm;
  const {
    isSubmitting: isSubmittingModalReplyForm,
    isValid: isModalReplyFormValid,
  } = modalReplyFormState;

  const prompt = usePrompt<boolean>();

  const handleSafeClose = async () => {
    if (isModalReplyFormValid) {
      const shouldClose = await prompt.prompt();
      if (shouldClose) {
        closeReplyDialog(() => {
          setPostIdToReplyTo(null);
          resetModalReplyForm();
        });
      }
    } else {
      closeReplyDialog(() => {
        setPostIdToReplyTo(null);
        resetModalReplyForm();
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
  const { updateScroll } = useRouterScroll();
  const ref = useCallback(
    (node) => {
      if (node) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        updateScroll();
      }
    },
    [updateScroll]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 600) {
        setIsWindowScrolled(false);
      } else {
        setIsWindowScrolled(true);
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  if (userError || sessionError || postError) {
    return <ErrorScreen error={userError ?? sessionError ?? postError} />;
  }

  const onSubmitInlineReplyForm = inlineReplyForm.handleSubmit(
    ({ replyBody }: FormValues) =>
      replyToPost({
        postId: post.post.id,
        replyId: post.post.id,
        body: replyBody,
        queryKeyPostId: postId,
      }).then((newPost) => {
        resetInlineReplyForm();
        resetPinnedInlineReplyForm();

        return scroller.scrollTo(`${newPost.id}`, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -150,
        });
      })
  );

  const onSubmitPinnedInlineReplyForm = pinnedInlineReplyForm.handleSubmit(
    ({ replyBody }: FormValues) =>
      replyToPost({
        postId: post.post.id,
        replyId: post.post.id,
        body: replyBody,
        queryKeyPostId: postId,
      }).then((newPost) => {
        resetInlineReplyForm();
        resetPinnedInlineReplyForm();

        return scroller.scrollTo(`${newPost.id}`, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -150,
        });
      })
  );

  const postToReplyTo =
    postIdToReplyTo === post?.post?.id
      ? post?.post
      : post?.replies?.find(({ id }) => postIdToReplyTo === id);

  const onSubmitModalReplyForm = modalReplyForm.handleSubmit(
    ({ replyBody }: FormValues) =>
      replyToPost({
        postId: post.post.id,
        // We only support posts up to a depth of 2.
        replyId:
          postToReplyTo?.db_depth > 1
            ? postToReplyTo.post_replied_to
            : postToReplyTo.id,
        body:
          // When it's a depth 2 post, we append the post author's handle
          // to the comment.
          postToReplyTo?.db_depth > 1
            ? `@${postToReplyTo?.community_handle} - ${replyBody}`
            : replyBody,
        queryKeyPostId: postId,
      }).then((newPost) => {
        closeReplyDialog(() => {
          setPostIdToReplyTo(null);
          resetModalReplyForm();
        });

        return scroller.scrollTo(`${newPost.id}`, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -150,
        });
      })
  );

  // Construct a title that looks like:
  // Sexual Orientation OCD, POCD: Hi My name is Stephen Smith and I'm the founder….
  const topics = [
    ...(post?.post?.topics ?? []).map(({ topic_title }) => topic_title),
    post?.post?.post_type === PostTypes.THERAPY_SESSION_REVIEW
      ? "Therapy Review"
      : null,
  ].filter(Boolean);
  const topicsPartOfMetaTitle = isEmpty(topics) ? "" : `${topics.join(", ")}: `;

  const metaTitle =
    post?.post?.seo_title ||
    post?.post?.posting_title ||
    truncate(`${topicsPartOfMetaTitle}${post?.post?.body}`, {
      length: 63,
    });

  const metaDescription =
    post?.post?.seo_description ||
    truncate(trim(post?.post?.body), { length: 160 });

  const isLoading = isLoadingPosts || isLoadingSession || isLoadingUser;

  return (
    <ContainedPageWithHeading
      title="Back to Community"
      ref={ref}
      useH1Element={false}
    >
      <div className="tablet:pb-24 pb-48 laptop:grid laptop:grid-cols-3 laptop:gap-x-6 -mx-2 tablet:-mx-0">
        {isLoading ? (
          <main className="laptop:col-span-2">
            <div className="flex justify-center items-center py-16 text-indigo-600 text-32px">
              <Loader />
            </div>
          </main>
        ) : (
          <main className="laptop:col-span-2">
            <NextSeo
              title={metaTitle}
              description={metaDescription}
              openGraph={{
                description: metaDescription,
                title: metaTitle,
              }}
              canonical={`${process.env.NEXT_PUBLIC_CANONICAL_URL}/community/posts/${postId}`}
            />

            <section
              className={cn(
                "bg-white rounded-2xl py-6 px-4 tablet:mx-0",
                "tablet:px-6 tablet:py-7.5 border border-gray-200 shadow-sm"
              )}
            >
              <div className={cn("relative")}>
                <PostCard
                  redirectAfterDeletion
                  id={`${post.post.id}`}
                  onClickReply={{
                    type: "button",
                    handler: (selectedPostId) => {
                      if (!session) {
                        openModal({
                          redirectAfterAuthentication: false,
                          variant: "community",
                        });
                        return;
                      }

                      setPostIdToReplyTo(selectedPostId);
                      openReplyDialog();
                    },
                  }}
                  variant="single"
                  post={post.post}
                  rootPostId={post.post.id}
                />
              </div>

              <div
                onClick={
                  session
                    ? undefined
                    : (event) => {
                        event.stopPropagation();

                        return openModal({
                          redirectAfterAuthentication: false,
                          variant: "community",
                        });
                      }
                }
                className={cn(
                  "fixed z-10 top-14 tablet:top-20 left-0 right-0 bg-white tablet:bg-opacity-0 py-6 tablet:py-0 shadow-md tablet:shadow-none",
                  "mx-auto px-4 transition-opacity laptop:grid laptop:max-w-[1072px] laptop:grid-cols-3 laptop:gap-x-6 laptop:px-6",
                  isWindowScrolled
                    ? "opacity-100"
                    : "pointer-events-none opacity-0"
                )}
              >
                <form
                  onSubmit={onSubmitPinnedInlineReplyForm}
                  className={cn(
                    "col-span-2 tablet:px-6",
                    !session && "pointer-events-none"
                  )}
                >
                  <div className="flex items-center">
                    <div className="flex flex-1 items-center space-x-2 p-4 rounded-2xl border border-indigo-300 bg-white tablet:shadow">
                      <Input
                        backgroundColor="white"
                        className="flex-1 !border-0 !p-0 !text-gray-900 !ring-0"
                        placeholder="Add your comment..."
                        {...registerPinnedInlineReplyForm("replyBody")}
                      />
                      <button
                        disabled={isSubmittingPinnedInlineReplyForm}
                        type="submit"
                      >
                        <dl>
                          <dd className="sr-only">Reply</dd>
                          <dt>
                            <p
                              className={cn(
                                "group-disabled:text-[#CBD5E1] text-15px font-bold leading-[19.95px] text-indigo-600",
                                !isPinnedInlineReplyFormValid && "invisible"
                              )}
                            >
                              Post
                            </p>
                          </dt>
                        </dl>
                      </button>
                    </div>
                  </div>
                  <UnsavedChangesWatcherCommunity
                    isDirty={isPinnedInlineReplyFormValid}
                  />
                </form>
                <div className="hidden laptop:block" />
              </div>
            </section>

            <div>
              <div
                onClick={
                  session
                    ? undefined
                    : (event) => {
                        event.stopPropagation();

                        return openModal({
                          redirectAfterAuthentication: false,
                          variant: "community",
                        });
                      }
                }
              >
                <form
                  onSubmit={onSubmitInlineReplyForm}
                  className={cn(
                    "static mb-5 bg-white z-10",
                    "rounded-2xl border-t border shadow-sm mt-4.5 border-gray-200",
                    !session && "pointer-events-none"
                  )}
                >
                  <div className="space-y-3 p-4">
                    <div className="flex space-x-4 items-center">
                      <Avatar
                        src={
                          user?.avatarUrl ??
                          FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS
                        }
                        alt={user?.communityHandle}
                        size="custom"
                        className="h-[3rem] w-[3rem] flex-shrink-0"
                      />

                      <p className="font-bold">You</p>
                    </div>

                    <div className="bg-white flex-grow rounded-2xl overflow-hidden border border-indigo-300">
                      <Textarea
                        classes={{
                          root: "flex-1",
                          input:
                            "bg-white focus:!ring-0 !border-0 focus:!border-0 focus:!shadow-none",
                        }}
                        label="Add comment"
                        hideLabel
                        id="replyBody"
                        minRows={2}
                        placeholder="Add your comment..."
                        {...registerInlineReplyForm("replyBody")}
                      />

                      <div className="flex justify-end px-4 pb-4">
                        <button
                          disabled={isSubmittingInlineReplyForm}
                          type="submit"
                          className="text-18px font-medium"
                        >
                          <dl>
                            <dd className="sr-only">Reply</dd>
                            <dt>
                              <p
                                className={cn(
                                  "group-disabled:text-[#CBD5E1] text-15px font-bold leading-[19.95px] text-indigo-600",
                                  !isInlineReplyFormValid && "invisible"
                                )}
                              >
                                Post
                              </p>
                            </dt>
                          </dl>
                        </button>
                      </div>
                    </div>
                  </div>
                  <UnsavedChangesWatcherCommunity
                    isDirty={isInlineReplyFormValid}
                  />
                </form>
              </div>

              <div className="relative">
                <div className="mt-4.5 tablet:mt-0 space-y-4.5 relative">
                  {post?.replies.map((reply) => {
                    return (
                      <div
                        id={`${reply.id}`}
                        className={cn(
                          "relative border border-gray-200 shadow-sm",
                          "bg-white p-4 rounded-2xl",
                          reply.db_depth > 1 && "ml-4 tablet:ml-6"
                        )}
                        key={`${reply.id}`}
                      >
                        <PostCard
                          variant="reply"
                          avatarSize={getAvatarSizeByDepth(reply.db_depth)}
                          onClickReply={{
                            type: "button",
                            handler: (selectedPostId) => {
                              if (!session) {
                                openModal({
                                  redirectAfterAuthentication: false,
                                  variant: "community",
                                });
                                return;
                              }

                              setPostIdToReplyTo(selectedPostId);
                              openReplyDialog();
                            },
                          }}
                          post={reply}
                          rootPostId={post.post.id}
                        />
                      </div>
                    );
                  })}
                </div>

                {!session && post?.replies?.length > 5 && (
                  <LoginPrompt
                    className={cn(
                      "bg-gradient-to-b from-transparent via-white to-white z-1",
                      "absolute tablet:-bottom-32 -bottom-52 pb-24 pt-84 w-full",
                      "w-[100vw] tablet:w-full  left-[50%] tablet:left-auto right-[50%] tablet:right-auto ml-[-50vw] tablet:mx-0 mr-[-50vw]"
                    )}
                  />
                )}
              </div>
            </div>
          </main>
        )}

        <div className="hidden laptop:block">
          <OcdJourneySidebar />
        </div>
      </div>

      {!isLoading && !!session ? (
        <Modal isOpen={isReplyDialogOpen} onClose={handleSafeClose}>
          <Modal.CloseButton onClick={handleSafeClose} />
          {postIdToReplyTo ? (
            <>
              <div className="relative">
                <PostCard
                  hideActions
                  rootPostId={post.post.id}
                  post={postToReplyTo}
                  variant="single"
                />
              </div>

              <form onSubmit={onSubmitModalReplyForm}>
                <div className="tablet:mb-6 flex items-start space-x-2.5 py-4">
                  <Avatar
                    src={
                      user?.avatarUrl ??
                      FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS
                    }
                    alt={user?.communityHandle}
                    size="custom"
                    className="h-[32px] w-[32px] flex-shrink-0 tablet:h-[40px] tablet:w-[40px]"
                  />

                  <div className="bg-white flex-grow rounded-2xl overflow-hidden border border-indigo-300">
                    <Textarea
                      classes={{
                        root: "flex-1",
                        input:
                          "bg-white focus:!ring-0 !border-0 focus:!border-0 focus:!shadow-none",
                      }}
                      label="Add comment"
                      hideLabel
                      id="replyBody"
                      minRows={2}
                      maxRows={5}
                      placeholder="Add your comment..."
                      {...registerModalReplyForm("replyBody")}
                    />

                    <div className="flex justify-end px-4 pb-4">
                      <button
                        disabled={isSubmittingModalReplyForm}
                        type="submit"
                        className="text-18px font-medium"
                      >
                        <dl>
                          <dd className="sr-only">Reply</dd>
                          <dt>
                            <p
                              className={cn(
                                "group-disabled:text-[#CBD5E1] text-15px font-bold leading-[19.95px] text-indigo-600",
                                !isModalReplyFormValid && "invisible"
                              )}
                            >
                              Post
                            </p>
                          </dt>
                        </dl>
                      </button>
                    </div>
                  </div>
                </div>
                <UnsavedChangesWatcherCommunity
                  isDirty={isModalReplyFormValid}
                />
              </form>
            </>
          ) : null}

          <ExitPromptModal
            isOpen={prompt.isOpen}
            onClose={() => prompt.onConfirm(false)}
            onConfirm={() => prompt.onConfirm(true)}
          />
        </Modal>
      ) : null}
    </ContainedPageWithHeading>
  );
}
