import { useSession } from "@core/hooks/useSession";
import { recordUnauthCommunityRegistrationClick } from "@core/services/gtm";
import Avatar from "@core/ui/Avatar";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import DotsHorizontalIcon from "@heroicons/react/outline/DotsHorizontalIcon";
import ChatIcon from "@heroicons/react/solid/ChatIcon";
import HeartIcon from "@heroicons/react/solid/HeartIcon";
import cn from "classnames";
import { truncate } from "lodash";
import { isEmpty, noop, trim } from "lodash/fp";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { MouseEvent, useCallback, useState } from "react";
import toast from "react-hot-toast";
import slugify from "slugify";

import { useMutatePostAction } from "../hooks/useMutatePostAction";
import {
  Post,
  PostActionIds,
  PostAvatarSizes,
  PostAvatarTypes,
  PostExt,
  PostTypes,
  RootPostId,
} from "../types";
import PostActionMenu from "./PostActionMenu";
import PostContent from "./PostContent";
import type { Variant } from "./PostContent/types";
import TriggerWarningModal from "./TriggerWarningModal";

export const getUserAvatarUrl = (post: Post | PostExt) => {
  if (
    [PostAvatarTypes.THERAPIST, PostAvatarTypes.ADVOCATE].includes(
      post.avatar_type
    ) &&
    // NOCD therapists and member advocates can have custom avatar images. This
    // is exposed via `avatar_img`, which contains the Cloudinary image ID.
    !isEmpty(trim(post.avatar_img))
  ) {
    return `https://res.cloudinary.com/nocdcloud/image/upload/c_crop,g_custom,r_max/w_55,h_55,dpr_2/v1530986461/${post.avatar_img}`;
  }

  return (
    post.avatar_url ??
    "https://assets.treatmyocd.com/images/avatars/avatar_0_circle.png"
  );
};

export interface PostCardProps {
  post: PostExt;
  className?: string;
  onClickReply?:
    | {
        type: "button";
        handler: (postId: Post["id"]) => void;
      }
    | {
        type: "link";
        href: string;
      };
  avatarSize?: PostAvatarSizes;
  rootPostId: RootPostId;
  /**
   * Setting this prop to `true` will hide the ability to like, comment, and
   * perform any other operations on the post.
   */
  hideActions?: boolean;
  id?: string;
  redirectAfterDeletion?: boolean;
  variant: Variant;
}

export const getUsernameToDisplay = (post: PostExt, variant: Variant) => {
  if (post.community_handle === "NOCD") {
    return "NOCD";
  }

  if (post.nocd_handle) {
    if (variant === "discover") {
      return truncate(post.nocd_handle, { length: 15 });
    }

    if (variant === "reply") {
      return (
        <>
          <span className="hidden tablet:inline">{post.nocd_handle}</span>
          <span className="tablet:hidden">
            {truncate(post.nocd_handle, { length: 15 })}
          </span>
        </>
      );
    }
    return post.nocd_handle;
  }

  if (variant !== "single") {
    return truncate(post.community_handle, { length: 15 });
  }
  return post.community_handle;
};

export default function PostCard({
  avatarSize = PostAvatarSizes.LARGE,
  className,
  onClickReply = {
    type: "button",
    handler: noop,
  },
  post,
  rootPostId,
  id,
  hideActions = false,
  redirectAfterDeletion = false,
  variant,
}: PostCardProps): JSX.Element {
  const { mutateAsync: performPostAction } = useMutatePostAction(rootPostId);
  const [isTriggerWarningModalOpen, setIsTriggerWarningModalOpen] =
    useState<boolean>(false);
  const { data: session } = useSession();
  const router = useRouter();

  const { openModal } = useAuthenticationModal();

  const { push } = useRouter();

  const handleClick = useCallback(
    (ev: MouseEvent) => {
      if (
        ["feed", "discover"].includes(variant) &&
        id &&
        (ev.target as HTMLElement).tagName !== "A"
      ) {
        void push(`/community/posts/${id}`);
      }
    },
    [variant, id, push]
  );

  const handleTriggerWarningClick = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      setIsTriggerWarningModalOpen(true);
    },
    [setIsTriggerWarningModalOpen]
  );

  const handleTopicClick = useCallback(
    async (ev: MouseEvent, slug: string) => {
      ev.stopPropagation();
      await router.push(`/community/topics/${slug}`);
    },
    [router]
  );

  const handleUnauthenticatedClick = useCallback(() => {
    try {
      recordUnauthCommunityRegistrationClick();
    } catch (gtmError) {
      console.error(
        "Error recording registration in GTM ",
        gtmError instanceof Error ? gtmError.message : ""
      );
    }
    openModal({
      redirectAfterAuthentication: false,
      variant: "community",
    });
  }, [openModal]);

  return (
    <article
      role="presentation"
      className={cn("relative text-[#1E293B]", className, {
        "cursor-pointer": ["feed", "discover"].includes(variant) && id,
      })}
      id={id}
      onClick={handleClick}
    >
      <div className="flex">
        {variant === "reply" ? (
          <Avatar
            size="custom"
            src={getUserAvatarUrl(post)}
            alt={post.community_handle}
            className={cn("rounded-full flex-shrink-0 mr-2", {
              "h-[32px] w-[32px] text-3xl tablet:h-[55px] tablet:w-[55px]":
                avatarSize === PostAvatarSizes.LARGE,
              "h-[24px] w-[24px] tablet:h-[40px] tablet:w-[40px]":
                avatarSize === PostAvatarSizes.MEDIUM,
              "h-[20px] w-[20px] tablet:h-[32px] tablet:w-[32px]":
                avatarSize === PostAvatarSizes.SMALL,
            })}
          />
        ) : null}

        <div className="flex-grow">
          <div className="flex flex-shrink-0 space-x-2 items-center ring-8 ring-white">
            {variant !== "reply" ? (
              <Avatar
                size="custom"
                src={getUserAvatarUrl(post)}
                alt={post.community_handle}
                className={cn("rounded-full", {
                  "h-[32px] w-[32px] text-3xl tablet:h-[55px] tablet:w-[55px]":
                    avatarSize === PostAvatarSizes.LARGE,
                  "h-[24px] w-[24px] tablet:h-[40px] tablet:w-[40px]":
                    avatarSize === PostAvatarSizes.MEDIUM,
                  "h-[20px] w-[20px] tablet:h-[32px] tablet:w-[32px]":
                    avatarSize === PostAvatarSizes.SMALL,
                })}
              />
            ) : null}

            <div className="flex justify-between flex-1 flex-grow text-[#1e293b]">
              <div className="text-14px">
                <dl className="ml-2">
                  <dt className="sr-only">Username</dt>
                  <dd>{getUsernameToDisplay(post, variant)}</dd>
                </dl>

                {post.badge?.background_color_hex &&
                post.badge?.image_url &&
                post.badge?.text &&
                post.badge?.text_color_hex ? (
                  <span
                    className="inline-flex rounded-full px-2 py-1 items-center"
                    style={{
                      backgroundColor: `#${post.badge?.background_color_hex}`,
                      color: `#${post.badge?.text_color_hex}`,
                    }}
                  >
                    <dl>
                      <dt className="sr-only">User type</dt>
                      <dd className="truncate">{post.badge?.text}</dd>
                    </dl>
                  </span>
                ) : null}

                <dl className="opacity-70 ml-2">
                  <dt className="sr-only">Date posted</dt>
                  <dd>{post.human_timestamp?.replace(" (Following)", "")}</dd>
                </dl>
              </div>

              {Boolean(session) && !hideActions ? (
                <div className="relative -mt-3">
                  <PostActionMenu
                    rootPostId={rootPostId}
                    post={post}
                    redirectAfterDeletion={redirectAfterDeletion}
                  />
                </div>
              ) : (
                <div onClick={(ev) => ev.stopPropagation()} role="presentation">
                  <button
                    type="button"
                    className="w-6 text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700"
                    onClick={handleUnauthenticatedClick}
                  >
                    <DotsHorizontalIcon className="w-6 text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700" />
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="flex-1">
            <TriggerWarningModal
              isOpen={isTriggerWarningModalOpen}
              handleClose={() => setIsTriggerWarningModalOpen(false)}
            />

            <div className="my-4">
              <PostContent post={post} variant={variant} />
            </div>

            {(Array.isArray(post.topics) && post.topics.length) ||
            post.trigger_warning ||
            post.post_type === PostTypes.THERAPY_SESSION_REVIEW ? (
              <ul className="-ml-1 flex flex-wrap">
                {post.trigger_warning ? (
                  <button type="button" onClick={handleTriggerWarningClick}>
                    <li className="m-1 rounded bg-red-200 py-1 px-3 text-14px leading-none text-red-500">
                      Trigger warning
                    </li>
                  </button>
                ) : null}

                {post.post_type === PostTypes.THERAPY_SESSION_REVIEW ? (
                  <li
                    key="therapy-review"
                    className="m-1 flex space-x-1.5 rounded bg-gray-100 py-1 pr-3 pl-1.5 text-14px leading-none text-gray-500"
                  >
                    {post.post_type_data?.badge_icon_image_url_svg && (
                      <Image
                        src={post.post_type_data?.badge_icon_image_url_svg}
                        width={16}
                        height={16}
                      />
                    )}
                    <span>{post.post_type_data?.badge_text}</span>
                  </li>
                ) : null}

                {(post.topics || []).map((item) => (
                  <li key={item.topic_id}>
                    <button
                      type="button"
                      onClick={(e) =>
                        handleTopicClick(
                          e,
                          slugify(item.topic_title, {
                            lower: true,
                            strict: true,
                          })
                        )
                      }
                      className="m-[2px] rounded-full bg-[#C4EEF1] py-2 font-bold px-4 text-12px leading-none text-teal-600"
                    >
                      {item.topic_title}
                    </button>
                  </li>
                ))}
              </ul>
            ) : null}

            {!hideActions && (
              <div className="flex space-x-4 text-14px mt-4">
                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    if (!session) {
                      return handleUnauthenticatedClick();
                    }

                    return performPostAction({
                      actionId: PostActionIds.LIKE_POST,
                      postId: post.id,
                      isEnabled: post.is_liked,
                      userId: null,
                    }).catch((error: Error) => toast.error(error.message));
                  }}
                  className="group flex items-center space-x-1 text-gray-500"
                >
                  <HeartIcon
                    className={cn(
                      "h-4.5 w-4.5",
                      post.is_liked
                        ? "fill-current stroke-current text-red-500 group-hover:text-red-600 group-focus:text-red-600"
                        : "stroke-current group-hover:text-gray-700 group-focus:text-gray-700"
                    )}
                  />
                  <span
                    className={cn(
                      post.is_liked
                        ? "text-red-500 group-hover:text-red-600"
                        : "group-hover:text-gray-700"
                    )}
                  >
                    {post.likes}
                  </span>
                </button>

                {onClickReply.type === "button" ? (
                  <button
                    type="button"
                    onClick={() => onClickReply.handler(post.id)}
                    className="flex items-center space-x-1 text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700"
                  >
                    <ChatIcon className="h-4.5 w-4.5" />
                    <span>{post.num_replies}</span>
                  </button>
                ) : (
                  <span
                    role="presentation"
                    // Prevent parent from firing click handler
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Link href={onClickReply.href}>
                      <a className="flex items-center space-x-1 text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700">
                        <ChatIcon className="h-4.5 w-4.5" />
                        <span>{post.num_replies}</span>
                      </a>
                    </Link>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}
