import { useSession } from "@core/hooks/useSession";
import cn from "classnames";
import { MouseEventHandler } from "react";
import toast from "react-hot-toast";

import { useMutatePostAction } from "../hooks/useMutatePostAction";
import { Post, PostActionIds, PostExt, RootPostId } from "../types";
import PostActionIcon from "./PostActionIcon";

const BookmarkIcon = ({ className }: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z"
      clipRule="evenodd"
    />
  </svg>
);

interface BookmarkButtonProps {
  post: Post | PostExt;
  rootPostId: RootPostId;
  className?: string;
}

export default function BookmarkButton({
  post,
  rootPostId,
  className,
}: BookmarkButtonProps) {
  const { data: session } = useSession();
  const { user } = session;
  const { mutateAsync: performPostAction } = useMutatePostAction(rootPostId);

  const isOwnPost = +post.user_id === +user.id;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    const actionId = post.isBookmarked
      ? PostActionIds.UNBOOKMARK_POST
      : PostActionIds.BOOKMARK_POST;
    const successMessage = post.isBookmarked
      ? "Post unbookmarked"
      : "Post bookmarked";

    return performPostAction({
      actionId,
      postId: post.id,
      isEnabled: false,
      userId: post.user_id,
    })
      .then(() =>
        toast.success(successMessage, {
          icon: (
            <PostActionIcon className="text-teal-600" actionId={actionId} />
          ),
        })
      )
      .catch((error: Error) => toast.error(error.message));
  };

  if (isOwnPost) {
    return null;
  }

  return (
    <button
      aria-label={post.isBookmarked ? "Unbookmark" : "Bookmark"}
      onClick={handleClick}
      type="button"
      className={className}
    >
      <BookmarkIcon
        className={cn(
          "w-5 h-5",
          post.isBookmarked ? "text-indigo-600" : "text-gray-300"
        )}
      />
    </button>
  );
}
