import { FC } from "react";

import PersonalizedFeed from "./PersonalizedFeed";
import PublicFeed from "./PublicFeed";

interface Props {
  isAuthenticated?: boolean;
  subtypeId?: string;
  segmentId?: string;
  setError: (error: Error) => void;
  baseUrl?: string;
}

const Feed: FC<Props> = ({
  isAuthenticated,
  subtypeId,
  segmentId,
  setError,
  baseUrl = "/community",
}) => {
  if (isAuthenticated) {
    return (
      <PersonalizedFeed
        subtypeId={subtypeId}
        baseUrl={baseUrl}
        setError={setError}
      />
    );
  }

  return (
    <PublicFeed
      segmentId={segmentId}
      subtypeId={subtypeId}
      baseUrl={baseUrl}
      setError={setError}
    />
  );
};

export default Feed;
