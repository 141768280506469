import { PostExt, PostTypes } from "@features/community-v2";
import { FC } from "react";

import Body from "./Body";
import { ContextProvider } from "./context";
import ImagePreview from "./ImagePreview";
import LinkPreview from "./LinkPreview";
import MultiImagePreview from "./MultiImagePreview";
import TherapyReview from "./TherapyReview";
import Title from "./Title";
import { Variant } from "./types";

interface Props {
  post: PostExt;
  variant: Variant;
  hideImage?: boolean;
}

const PostContent: FC<Props> = ({ post, variant, hideImage = false }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { posting_title, body, post_type_data, post_type } = post;

  return (
    <ContextProvider variant={variant} postId={post.id}>
      <div className="space-y-1">
        {posting_title && post_type !== PostTypes.POST_CAROUSEL && (
          <Title title={posting_title} />
        )}

        {body && post_type !== PostTypes.POST_CAROUSEL && (
          <Body body={body} hasTitle={!!posting_title} />
        )}

        {post_type === PostTypes.VIDEO && (
          <ImagePreview
            imageAspectRatio={post_type_data.aspect_ratio}
            imageUrl={post_type_data.image_url}
            url={post_type_data.url}
            cloudinaryImageId={post_type_data.image_public_id}
          />
        )}

        {post_type === PostTypes.IMAGE_V1 && !hideImage && (
          <ImagePreview
            imageAspectRatio={post_type_data.aspect_ratio}
            imageUrl={post_type_data.url}
            url={post_type_data.link}
            cloudinaryImageId={post_type_data.image_public_id}
          />
        )}

        {post_type === PostTypes.LINK && (
          <LinkPreview
            title={post_type_data.title}
            description={post_type_data.description}
            imageAspectRatio={post_type_data.aspect_ratio}
            imageUrl={post_type_data.image_url}
            url={post_type_data.url}
            cloudinaryImageId={post_type_data.image_public_id}
          />
        )}

        {post_type === PostTypes.THERAPY_SESSION_REVIEW && (
          <TherapyReview
            imageUrl={post_type_data.image_url}
            title={post_type_data.title}
            description={post_type_data.description}
            callToActionUrl={post_type_data.cta_url}
            callToActionIconUrl={post_type_data.cta_icon_image_url_svg}
          />
        )}

        {post_type === PostTypes.MULTI_IMAGE &&
          !hideImage &&
          Array.isArray(post_type_data.images) &&
          post_type_data.images.length > 0 && (
            <MultiImagePreview images={post_type_data.images} />
          )}
      </div>
    </ContextProvider>
  );
};

export default PostContent;
